<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-select
    v-if="fileContent && getVolumeNameListCompose[tableItem.serviceName].length"
    :id="`iiotDockerConfigurationStorageSelect_${index}`"
    :data-cy="`iiotDockerConfigurationStorageSelect_${index}`"
    v-model="tableItem.dockerConfigurationStorage"
    :menu-props="{ maxHeight: '150' }"
    attach
    :items="getVolumeNameListCompose[tableItem.serviceName]"
    :item-text="item => item.volumeName ?
      (item.containerPath ? `${item.volumeName}:${item.containerPath}` : item.volumeName) : ''"
    :disabled="version.released"
    class="selector"
    placeholder="None"
    @change="setConfigurationStorage"
  />
</template>

<script>
import DockerConfigurationStorageModel from '@/model/workloads/docker-configuration-storage.model';

export default {
  data: () => ({
    configurationStorageList: [],
  }),
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fileContent() {
      return this.$store.getters['workloads/fileContent'];
    },
    getVolumeNameListCompose() {
      return this.$store.getters['workloads/getVolumeNameListCompose'];
    },
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
  },
  methods: {
    setConfigurationStorage() {
      // eslint-disable-next-line max-len
      const storage = {};
      const volume = this.tableItem.dockerConfigurationStorage.split(':');
      if (volume.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        storage.volumeName = volume[0];
        // eslint-disable-next-line prefer-destructuring
        storage.containerPath = volume[1];
      } else {
        storage.restartOnConfigurationUpdate = false;
      }
      storage.serviceName = this.tableItem.serviceName;
      // eslint-disable-next-line max-len
      if (this.version.workloadSpecificProperties.dockerConfigurationStorage.find((dockerConfigurationStorage) => dockerConfigurationStorage.serviceName === this.tableItem.serviceName)) {
        // eslint-disable-next-line max-len
        this.version.workloadSpecificProperties.dockerConfigurationStorage = this.version.workloadSpecificProperties.dockerConfigurationStorage.map((dockerConfStorage) => {
          if (dockerConfStorage.serviceName === this.tableItem.serviceName) {
            dockerConfStorage = new DockerConfigurationStorageModel(storage);
          }
          return dockerConfStorage;
        });
      } else {
        // eslint-disable-next-line max-len
        this.version.workloadSpecificProperties.dockerConfigurationStorage.push(new DockerConfigurationStorageModel(storage));
      }
      this.tableItem.dockerConfigurationStorage = this.version.workloadSpecificProperties
        // eslint-disable-next-line max-len
        .dockerConfigurationStorage.find((dockerConfigurationStorage) => dockerConfigurationStorage.serviceName === this.tableItem.serviceName);
      if (this.version.services.find((service) => service.file.status !== 'available')) {
        this.$store.dispatch('workloads/set_save_enabled');
        return;
      }
      this.$store.dispatch('workloads/set_save_enabled', this.version);
    },
  },
};
</script>

<style scoped>
.selector{
  max-width: unset !important;
}
</style>
